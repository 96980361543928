<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Платежные реквизиты</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-card
        v-if="!loadPage && !commerceStatus"
        class="doc-menu-card pa-4 mb-4"
      >
        <div class="document-errors">
          <h3>Необходимо заполнить данные</h3>
          <settings-author-form @update-form="updateAuthor">
          </settings-author-form>
        </div>
      </v-card>
      <div v-if="!loadPage && commerceStatus">
        <v-card
          class="doc-menu-card deep-purple lighten-5 elevation-0 pd-info mb-4"
        >
          <v-card-text>
            Введите в поле ниже ваш кошелек ЮMoney. Кошелек ЮMoney обязательно должен быть именным и/или идентифицированным. На этот кошелек будет поступать ваше Роялти с продажи книг напрямую сразу после произведения оплаты книги Читателем. 
          </v-card-text>
        </v-card>

        <!-- <document-card v-for="(document, index) in documents" :key="index" :doc="document"></document-card> -->
        <!-- <pd-card></pd-card> -->
        <pd-card
          v-for="(requisite, index) in requisites"
          :key="requisite.id"
          :requisite="requisite"
          :index="index"
        ></pd-card>

        <v-card
          class="pd-add doc-menu-card"
          v-if="requisiteListFilter.length > 0"
        >
          <v-card-title>Добавить реквизиты:</v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form">
              <v-select
                v-model="pdType"
                :items="requisiteListFilter"
                item-text="title"
                item-value="slug"
                label="Выберите тип реквизитов"
                :rules="pdTypeRules"
              ></v-select>

              <div v-show="pdType">
                <v-row>
                  <v-col>
                    <v-text-field
                      name="inputNumber"
                      :label="pdType == 'yoomoney' ? 'Кошелек' : 'Номер карты'"
                      v-model="pdNumber"
                      :rules="pdNumberRules"
                    ></v-text-field>
                    <v-checkbox class="register-accept" v-model="accept">
                      <template v-slot:label>
                        <div>
                          Я согласен с
                          <router-link
                            :to="{
                              name: 'Page',
                              params: {
                                slug: 'usloviya-ispolzovaniya-yoomoney',
                              },
                            }"
                            >Условиями использования функционала пополнения
                            кошелька ЮMoney на платформе Bookstab</router-link
                          >
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col v-if="showPdInput">
                    <v-text-field
                      v-if="showPdInput"
                      name="inputNumber"
                      label="Владелец карты"
                      class="input-uppercase"
                      v-model="pdHolder"
                      :rules="pdHolderRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-menu
                  v-if="showPdInput"
                  ref="date_menu"
                  v-model="date_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <!-- :return-value.sync="dates" -->
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="pdDate"
                      :rules="pdDateRules"
                      label="Срок окончания действия карты"
                      prepend-icon="$vuetify.icons.calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="ru-ru"
                    ref="picker"
                    :first-day-of-week="1"
                    v-model="pdDate"
                    :min="new Date().toISOString().substr(0, 10)"
                    type="month"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="date_menu = false"
                      >Закрыть</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.date_menu.save(pdDate)"
                      >Принять</v-btn
                    >
                  </v-date-picker>
                </v-menu>
                <v-btn
                  dark
                  color="primary"
                  class="register-button"
                  :disabled="!accept"
                  @click="addPd"
                  >Добавить</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>
<script>
import PdCard from "./PdCard.vue";
import { mapGetters } from "vuex";
import SettingsAuthorForm from "../SettingsAuthorForm.vue";
export default {
  components: {
    PdCard,
    SettingsAuthorForm,
  },
  data: () => ({
    valid: true,
    sendLoading: false,
    pdType: null,
    // pdItems: [
    //   { slug: "yoomoney", title: "YooMoney счет" },
    //   { slug: "bankcard", title: "Банковская карта" },
    // ],
    pdNumber: null,
    accept: true,
    pdHolder: null,
    pdDate: null,
    date_menu: false,
    ruleNumbers: (v) => !v || /^[\d]*$/.test(v) || "Только цифры",
    ruleEng: (v) => /^[\w- ]*$/.test(v) || "Только латинские буквы.",
    requiredRule: (v) => !!v || "Поле обязательно",
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    addPd() {
      if (!this.validate()) return;
      this.sendLoading = true;

      let app = this;

      this.$store
        .dispatch("requisites/addRequisite", {
          number: app.pdNumber,
          holder: app.pdHolder,
          expiration_date: app.pdDate,
          type: app.pdType,
        })
        .then(() => {
          app.sendLoading = false;
          app.clear();
        })
        .catch(() => {
          app.sendLoading = false;
        });
    },
    updateAuthor() {
      this.getRequisites();
    },
    getRequisites() {
      var app = this;

      this.$store
        .dispatch("requisites/getRequisites", {
          page: app.page,
          sort: app.sort,
          type: app.type,
        })
        .then(() => {});
    },
    clear() {
      this.pdNumber = null;
      this.pdHolder = null;
      this.pdDate = null;
      this.pdType = null;
    },
    countPd(key) {
      let count = 0;
      for (let i = 0; i < this.requisites.length; i++) {
        if (this.requisites[i].type == key) {
          count++;
        }
      }

      return count;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      requisites: "requisites/requisites",
      requisiteList: "requisites/requisiteList",
      commerceStatus: "requisites/commerceStatus",
    }),
    requisiteListFilter() {
      let app = this;
      if (app.requisiteList && app.requisites) {
        return app.requisiteList.filter(function (el) {
          if (app.countPd(el.slug) > 0) {
            return false;
          }
          return true;
        });
      }
      return [];
    },
    pdTypeRules() {
      const rules = [];
      rules.push(this.requiredRule);
      return rules;
    },
    pdNumberRules() {
      const rules = [];
      rules.push(this.requiredRule);

      // const rule2 = (v) => (v && v.length >= 3) || "Минимум 3 символа";
      // rules.push(rule2);

      rules.push(this.ruleNumbers);
      return rules;
    },
    pdHolderRules() {
      const rules = [];
      if (this.pdType == "bankcard") {
        rules.push(this.requiredRule);
      }
      rules.push(this.ruleEng);
      return rules;
    },
    pdDateRules() {
      const rules = [];
      if (this.pdType == "bankcard") {
        rules.push(this.requiredRule);
      }
      return rules;
    },
    showPdInput() {
      if (this.pdType == "bankcard") {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getRequisites();
  },
};
</script>
