<template>
  <v-col v-if="requisite" class="text-left" cols="12">
    <v-card class="pd-card doc-menu-card">
      <v-card-title>{{ requisiteType.title }} 
        <v-chip v-if="+requisite.status === 1" dark color="success" label small class="ml-4">
          <v-icon small left> $vuetify.icons.check </v-icon>
          Сохранен
        </v-chip>
        <v-chip v-else dark color="gray" label small class="ml-4">
          <v-icon small left> $vuetify.icons.check </v-icon>
          Не подтвержден
        </v-chip>
        </v-card-title>
      <!-- <v-card-subtitle>{{ requisiteType.descr }}</v-card-subtitle> -->
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col clos-12 md="6">
              <v-text-field
                name="inputNumber"
                :label="requisite.type == 'yoomoney' ? 'Кошелек ЮMoney' : 'Номер карты'"
                v-model="pdNumber"
                :rules="pdNumberRules"
                :disabled=editDisable
              ></v-text-field>
            </v-col>
            <v-col clos-12 md="6">
              <v-text-field
                v-if="showPdInput"
                name="inputNumber"
                label="Владелец карты"
                class="input-uppercase"
                v-model="pdHolder"
                :rules="pdHolderRules"
                :disabled=editDisable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-menu
            v-if="showPdInput"
            ref="date_menu"
            v-model="date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <!-- :return-value.sync="dates" -->
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="pdDate"
                :rules="pdDateRules"
                label="Срок окончания действия карты"
                prepend-icon="$vuetify.icons.calendar"
                readonly
                v-on="on"
                :disabled=editDisable
              ></v-text-field>
            </template>
            <v-date-picker
              locale="ru-ru"
              ref="picker"
              :first-day-of-week="1"
              v-model="pdDate"
              :min="new Date().toISOString().substr(0, 10)"
              type="month"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date_menu = false"
                >Закрыть</v-btn
              >
              <v-btn text color="primary" @click="$refs.date_menu.save(pdDate)"
                >Принять</v-btn
              >
            </v-date-picker>
          </v-menu>
          <v-btn :loading="sendLoading" v-if="!editDisable" dark color="primary" @click="editPd">Изменить</v-btn>
        </v-form>
      </v-card-text>

      <v-card-actions class="mt-auto px-4 pb-4 pt-0">
        <div v-if="+requisite.status !== 1 && editDisable" class="sms-action pa-4">
          <v-btn
            v-if="showCode"
            color="primary"
            tile
            text
            small
            class="px-0"
            :loading="codeLoading"
            @click="sendCode"
            >Отправить смс снова</v-btn
          >
          <div v-if="requisite.existSms">
          <v-form  v-model="validCode" ref="formCode">
            <div class="d-flex align-center">
              <v-text-field
                v-model="smsCode"
                name="inputSmsCode"
                label="Введите код"
                :rules="requiredRules"
              ></v-text-field>
              <v-btn
                class="ml-2"
                color="primary"
                small
                :loading="codeLoading"
                @click="sendVerifyCode"
                >Подтвердить</v-btn
              >
            </div>
          </v-form>
          </div>
        </div>
        
        <v-spacer> </v-spacer>
        <v-btn icon @click="editDisable = !editDisable">
          <v-icon>$vuetify.icons.square_edit_outline</v-icon>
        </v-btn>
        <v-btn @click.stop="deletePd" icon>
          <v-icon>$vuetify.icons.delete</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
// import BookInfo from "./BookInfo.vue";
export default {
  // components: { BookInfo },
  props: ["requisite"],
  data() {
    return {
      valid: true,
      validCode: true,
      editDisable:true,
      showCode: true,
      smsCode: "",
      codeLoading: false,
      requiredRules: [(v) => !!v || "Поле обязательно"],
sendLoading: false,
      pdNumber: null,
      pdHolder: null,
      pdDate: null,
      date_menu: false,
      ruleNumbers: (v) => !v || /^[\d]*$/.test(v) || "Только цифры",
      ruleEng: (v) => /^[\w-]*$/.test(v) || "Только латинские буквы.",
      requiredRule: (v) => !!v || "Поле обязательно",
    };
  },
  methods: {
    
    validate() {
      return this.$refs.form.validate();
    },
    validateCode() {
      return this.$refs.formCode.validate();
    },
    sendCode() {
      let app = this;
      this.$store
        .dispatch("requisites/sendCode", {
          id: app.requisite.id,
        })
        .then(() => {
          app.codeLoading = false;
        })
        .catch(() => {
          app.codeLoading = false;
        });
    },
    sendVerifyCode() {
      if (!this.validateCode()) return;
      let app = this;
      this.$store
        .dispatch("requisites/sendVerifyCode", {
          smsCode: app.smsCode,
          id: app.requisite.id,
        })
        .then(() => {
          app.codeLoading = false;
        })
        .catch(() => {
          app.codeLoading = false;
        });
    },
    deletePd() {
      let app = this;
      this.$store
        .dispatch("requisites/destroy", {
          id: app.requisite.id,
        });
    },
    editPd() {
      if (!this.validate()) return;
      this.sendLoading = true;
      let app = this;
      this.$store
        .dispatch("requisites/updateRequisite", {
          number: app.pdNumber,
          holder: app.pdHolder,
          expiration_date: app.pdDate,
          id: app.requisite.id,

        }).then(() => {
           app.sendLoading = false;
           app.editDisable = true;
        }).catch(()=> {
          app.sendLoading = false;
        })
        ;
    },
    updateData() {
      if (this.requisite) {
        this.pdNumber = this.requisite.number;
        if (this.requisite.type == "bankcard") {
          this.pdHolder = this.requisite.holder;
          this.pdDate = this.requisite.expiration_date.substr(0, 7);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      requisiteTypes: "requisites/requisiteTypes",
    }),

    requisiteType() {
      if (this.requisite) {
        return this.requisiteTypes[this.requisite.type];
      }
      return null;
    },

    pdNumberRules() {
      const rules = [];
      rules.push(this.requiredRule);

      // const rule2 = (v) => (v && v.length >= 3) || "Минимум 3 символа";
      // rules.push(rule2);

      rules.push(this.ruleNumbers);
      return rules;
    },
    pdHolderRules() {
      const rules = [];
      if (this.requisite.type == "bankcard") {
        rules.push(this.requiredRule);
      }
      rules.push(this.ruleEng);
      return rules;
    },
    pdDateRules() {
      const rules = [];
      if (this.requisite.type == "bankcard") {
        rules.push(this.requiredRule);
      }
      return rules;
    },
    showPdInput() {
      if (this.requisite.type == "bankcard") {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>
